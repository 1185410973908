<template>
    <v-card class="card-shadow">
        <div class="card-title-wrapper pa-6">
            <div class="d-flex justify-space-between align-center">
                <div>
                    <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">Manage Users</v-card-title>
                    <v-card-subtitle class="text-subtitle-1 pa-0">Use this page to add, remove and modify user accounts. </v-card-subtitle>
                </div>
                <div>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="setNewUser()" elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF" v-bind="attrs" v-on="on">
                                New User
                            </v-btn>
                        </template>
                        <v-form ref="create">
                            <v-card>
                                <v-card-title class="text-h2 font-weight-600 pa-6">Create User</v-card-title>
                                <v-card-text class="pa-6 pb-0 pt-0">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="6" class="pb-0">
                                                <label for="firstName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">First Name</label>
                                                <v-text-field
                                                    :rules="[rules.required]"
                                                    autocomplete="off"
                                                    hide-details="auto"
                                                    validate-on-blur
                                                    id="firstName"
                                                    v-model="newUser.first_name"
                                                    outlined
                                                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                                                    placeholder="John">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="pb-0">
                                                <label for="lastName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Last Name</label>
                                                <v-text-field
                                                    :rules="[rules.required]"
                                                    autocomplete="off"
                                                    hide-details="auto"
                                                    validate-on-blur
                                                    id="lastName"
                                                    v-model="newUser.last_name"
                                                    outlined
                                                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                                                    placeholder="Deere">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <label for="email" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Email Address</label>
                                                <v-text-field
                                                    :rules="[rules.required, rules.email]"
                                                    autocomplete="off"
                                                    hide-details="auto"
                                                    validate-on-blur
                                                    id="email"
                                                    v-model="newUser.email"
                                                    outlined
                                                    class="input-style font-size-input text-color-gray mt-0 mb-6"
                                                    placeholder="johndoe@gmail.com">
                                                </v-text-field>
                                                <label for="role" class="label-color font-weight-600 subtitle-2 mb-2 d-block">User Role</label>
                                                <v-select
                                                    :items="userRoleList"
                                                    :rules="[rules.required]"
                                                    autocomplete="off"
                                                    hide-details="auto"
                                                    validate-on-blur
                                                    v-model="newUser.role"
                                                    placeholder="Role"
                                                    clearable
                                                    outlined
                                                    class="input-style font-size-input text-color-gray placeholder-light select-style mt-0 mb-6"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions class="pa-6 pt-0">
                                    <v-btn
                                        elevation="0"
                                        height="43"
                                        class="font-weight-600 text-capitalize btn-ls btn-outline-primary py-3 px-6 rounded-sm me-2 my-2"
                                        color="transparent"
                                        @click="dialog = false"
                                        >Cancel</v-btn
                                    >
                                    <v-btn elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF" @click="saveUser()">Invite User</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </div>
            </div>
        </div>
        <v-card-text class="pa-6">
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="usersList" :items-per-page="perPage" :page="currentPage" hide-default-footer>
                        <template v-slot:[`item.isVerified`]="{ item }">
                            <v-simple-checkbox v-model="item.isVerified" disabled> </v-simple-checkbox>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu top :close-on-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-dialog v-model="editDialogs[item.id]" max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item @click="setEditUser(item)" v-bind="attrs" v-on="on">
                                                <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <v-form ref="edit">
                                            <v-card>
                                                <v-card-title class="text-h2 font-weight-600 pa-6">Edit User</v-card-title>
                                                <v-card-text class="pa-6 pb-0 pt-0">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="6" class="pb-0">
                                                                <label for="firstName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">First Name</label>
                                                                <v-text-field
                                                                    :rules="[rules.required]"
                                                                    autocomplete="off"
                                                                    hide-details="auto"
                                                                    validate-on-blur
                                                                    id="firstName"
                                                                    v-model="editUser.first_name"
                                                                    outlined
                                                                    class="input-style font-size-input text-color-gray mt-0 mb-2"
                                                                    placeholder="John">
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" class="pb-0">
                                                                <label for="lastName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Last Name</label>
                                                                <v-text-field
                                                                    :rules="[rules.required]"
                                                                    autocomplete="off"
                                                                    hide-details="auto"
                                                                    validate-on-blur
                                                                    id="lastName"
                                                                    v-model="editUser.last_name"
                                                                    outlined
                                                                    class="input-style font-size-input text-color-gray mt-0 mb-2"
                                                                    placeholder="Deere">
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <label for="email" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Email Address</label>
                                                                <v-text-field
                                                                    :rules="[rules.required, rules.email]"
                                                                    autocomplete="off"
                                                                    hide-details="auto"
                                                                    validate-on-blur
                                                                    id="email"
                                                                    v-model="editUser.email"
                                                                    outlined
                                                                    class="input-style font-size-input text-color-gray mt-0 mb-6"
                                                                    placeholder="johndoe@gmail.com">
                                                                </v-text-field>
                                                                <label
                                                                    v-if="authUser.id !== item.id && checkIfUserCanSetRole(editUser.role)"
                                                                    for="role"
                                                                    class="label-color font-weight-600 subtitle-2 mb-2 d-block"
                                                                    >User Role</label
                                                                >
                                                                <v-select
                                                                    :rules="[rules.required]"
                                                                    autocomplete="off"
                                                                    hide-details="auto"
                                                                    validate-on-blur
                                                                    v-if="authUser.id !== item.id && checkIfUserCanSetRole(editUser.role)"
                                                                    :items="userRoleList"
                                                                    v-model="editUser.role"
                                                                    placeholder="Role"
                                                                    clearable
                                                                    outlined
                                                                    class="input-style font-size-input text-color-gray placeholder-light select-style mt-0 mb-6"></v-select>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>

                                                <v-card-actions class="pa-6 pt-0">
                                                    <v-btn
                                                        elevation="0"
                                                        height="43"
                                                        class="font-weight-600 text-capitalize btn-ls btn-outline-primary py-3 px-6 rounded-sm me-2 my-2"
                                                        color="transparent"
                                                        @click="
                                                            editDialogs[item.id] = false;
                                                            editUser = {};
                                                        "
                                                        >Cancel</v-btn
                                                    >
                                                    <v-btn
                                                        elevation="0"
                                                        height="43"
                                                        class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
                                                        color="#6F70FF"
                                                        @click="saveUser(editUser)"
                                                        >Save Changes</v-btn
                                                    >
                                                </v-card-actions>
                                            </v-card>
                                        </v-form>
                                    </v-dialog>
                                    <v-dialog v-if="authUser.id !== item.id" v-model="deleteDialog" max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <v-card>
                                            <v-card-title class="text-h2 font-weight-600">Delete</v-card-title>
                                            <v-card-text>
                                                Are you sure you want to delete user <span class="font-weight-600">{{ item.first_name }} {{ item.last_name }}</span
                                                >?
                                            </v-card-text>
                                            <v-card-actions class="pl-6">
                                                <v-btn
                                                    elevation="0"
                                                    height="43"
                                                    class="font-weight-600 text-capitalize btn-ls btn-outline-primary py-3 px-6 rounded-sm me-2 mb-2"
                                                    color="transparent"
                                                    @click="deleteDialog = false"
                                                    >No</v-btn
                                                >
                                                <v-btn elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 mb-2 rounded-sm" color="#6F70FF" @click="deleteUser(item)"
                                                    >Yes</v-btn
                                                >
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <div class="mt-4 d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="totalPagesLength" circle></v-pagination>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { models } from 'feathers-vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            editDialogs: {},
            newUser: {},
            editUser: {},
            currentPage: 1,
            perPage: 10,
            totalPages: 1,
            headers: [
                { text: 'First Name', value: 'first_name', sortable: true, align: 'start' },
                { text: 'Last Name', value: 'last_name', sortable: true, align: 'start' },
                { text: 'Email Address', value: 'email', sortable: true, align: 'start' },
                { text: 'Role', value: 'role', sortable: true, align: 'start' },
                { text: 'Verified', value: 'isVerified', sortable: false, align: 'start' },
                { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
            ],
            dialog: false,
            editDialog: false,
            deleteDialog: false,
            setPassword: false,
            editShowPassword: false,
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    return pattern.test(value) || 'Invalid e-mail.';
                }
            }
        };
    },
    mixins: [makeFindMixin({ service: 'users', watch: true })],
    methods: {
        async saveUser(user) {
            try {
                if (user) {
                    if (this.$refs.edit.validate()) {
                        await user.save();
                        this.showSuccess('Successfully edited user!');
                        this.editDialog = false;
                    }
                } else {
                    if (this.$refs.create.validate()) {
                        await this.newUser.save();
                        this.showSuccess('Successfully invited a new user!');
                        this.dialog = false;
                        this.refresh();
                    }
                }
            } catch (error) {
                this.showError(error);
            }
        },
        async deleteUser(user) {
            try {
                await user.remove();
                this.refresh();
                this.showSuccess('User successfully removed!');
                this.deleteDialog = false;
            } catch (error) {
                this.showError(error);
            }
        },
        setNewUser() {
            const { User } = models.api;
            this.newUser = new User();
        },
        setEditUser(user) {
            const { User } = models.api;
            this.editUser = Object.assign(new User(), user);
        },
        refresh() {
            this.findUsers();
        },
        ...mapActions('app', ['showSuccess', 'showError']),
        checkIfUserCanSetRole(editingRole) {
            if (this.authUser.role === 'Admin') return true;
            if (this.authUser.role === 'GlobalManager' && editingRole === 'Admin') return false;
            if (this.authUser.role === 'Manager' && ['GlobalManager', 'Admin'].indexOf(editingRole) !== -1) return false;
            if (this.authUser.role === 'Employee' && ['GlobalManager', 'Admin', 'Manager'].indexOf(editingRole) !== -1) return false;
            return true;
        }
    },
    computed: {
        userRoleList() {
            if (this.authUser.role === 'Admin') return ['GlobalManager', 'Manager', 'Employee'];
            if (this.authUser.role === 'GlobalManager') return ['Manager', 'Employee'];
            if (this.authUser.role === 'Manager') return ['Manager', 'Employee'];
            if (this.authUser.role === 'Employee') return ['Employee'];
            return [];
        },
        usersList() {
            return this.users.filter((user) => user.role !== 'Admin');
        },
        usersParams() {
            if (!this.authUser) return;

            return {
                query: {
                    $limit: this.perPage,
                    $skip: (this.currentPage - 1) * this.perPage
                }
            };
        },
        totalPagesLength() {
            const usersPaginationData = this.usersPaginationData.default;
            if (usersPaginationData && usersPaginationData.mostRecent.total) {
                this.totalPages = Math.ceil(usersPaginationData.mostRecent.total / this.perPage);

                if (usersPaginationData.mostRecent.total / this.totalPages === this.perPage) {
                    this.currentPage = 1;
                }
            }

            return this.totalPages;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    },
    watch: {
        users: {
            handler(newUsers) {
                if (!newUsers) return;
                var o = {};
                this.users.forEach((u) => {
                    o[u.id] = false;
                });
                editDialogs = o;
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss">
.eye {
    &:hover {
        cursor: pointer;
    }
}
</style>
