<template>
    <v-card class="card-shadow">
        <div class="card-title-wrapper pa-6">
            <v-row>
                <v-col cols="8">
                    <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">My Transferred Forms</v-card-title>
                    <v-card-subtitle class="text-subtitle-1 pa-0">These active submissions have been transferred between users and have not been submitted yet.</v-card-subtitle>
                </v-col>
            </v-row>
        </div>

        <v-card-text class="pa-6">
            <v-row>
                <v-col cols="12">
                    <v-data-table v-if="transferredFormsParsed.length" :headers="transferredHeaders" :items="transferredFormsParsed" hide-default-footer>
                        <template v-slot:[`item.form_name`]="{ item }">
                            <div class="d-flex align-center">
                                <feather type="file-text" class="text-grey" size="1.2rem" stroke-width="1.2"></feather>
                                <v-btn class="font-weight-600 text-capitalize px-2" text>
                                    {{ item.form_name }}
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:[`item.form_transferred_date`]="{ item }">
                            <v-chip color="#6F70FF" class="text-white text-caption font-weight-600">
                                {{ item.form_transferred_date }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.form_transfer_history`]="{ item }">
                            <div class="d-flex align-center">
                                <v-chip v-if="authUser.first_name === item.form_transfer_history[0].first_name" color="#6F70FF" class="text-white text-caption font-weight-600">
                                    {{ item.form_transfer_history[0].first_name }} {{ item.form_transfer_history[0].last_name }}
                                </v-chip>
                                <span v-else>{{ item.form_transfer_history[0].first_name }} {{ item.form_transfer_history[0].last_name }}</span>
                                <feather type="arrow-right" class="text-grey mx-2" size="16" stroke-width="1.5"></feather>
                                <v-chip v-if="authUser.first_name === item.form_transfer_history[1].first_name" color="#6F70FF" class="text-white text-caption font-weight-600">
                                    {{ item.form_transfer_history[1].first_name }} {{ item.form_transfer_history[1].last_name }}
                                </v-chip>
                                <span v-else>{{ item.form_transfer_history[1].first_name }} {{ item.form_transfer_history[1].last_name }}</span>
                            </div>
                        </template>
                    </v-data-table>
                    <v-card v-else>
                        <v-card-title class="ml-4">
                            <span class="text-muted text-caption ls-0 font-weight-600">You currently have 0 transferred forms.</span>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <div class="mt-4 d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="totalPagesLength" circle></v-pagination>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    components: {},
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            totalPages: 1,
            transferredForms: [],
            transferredHeaders: [
                { text: 'Form Name', value: 'form_name' },
                { text: 'Transfer Date', value: 'form_transferred_date' },
                { text: 'Transfer History', value: 'form_transfer_history' }
            ]
        };
    },
    mounted() {
        this.transferred();
    },
    methods: {
        retrieveUser(userId) {
            const foundUser = this.users.find((user) => user.id === userId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        createdAt(time) {
            var d = new Date(time);
            return this.$moment(d).calendar();
        },
        async transferred() {
            if (this.authUser.role === 'Admin' || this.authUser.role === 'Manager' || this.authUser.role === 'GlobalManager') {
                const result = await this.$store.dispatch('overview/find', {
                    query: {
                        manager_id: this.authUser.id
                    }
                });

                if (result[2].length) {
                    this.transferredForms = result[2];
                }
            } else {
                const result = await this.$store.dispatch('overview/find', {
                    query: {
                        user_id: this.authUser.id
                    }
                });

                if (result[2].length) {
                    this.transferredForms = result[2];
                }
            }
        },
        transferHistory(from, to) {
            const transferHistory = [from, to];
            return transferHistory;
        }
    },
    computed: {
        transferredFormsParsed: function () {
            if (!this.upcomingFormsPaginated) return [];
            return this.upcomingFormsPaginated.map((form) => {
                const d = {
                    form_name: form.name,
                    form_transferred_date: this.$moment(form.transferredAt).format('Do MMMM YYYY, HH:mm'),
                    form_transfer_history: this.transferHistory(form.transferred_by_user, form.transferred_to_user)
                };
                return d;
            });
        },
        upcomingFormsPaginated() {
            const firstIndex = (this.currentPage - 1) * this.perPage;
            const lastIndex = this.perPage * this.currentPage;

            if (this.transferredForms && this.transferredForms.length) {
                return this.transferredForms.slice(firstIndex, lastIndex);
            } else {
                return [];
            }
        },
        totalPagesLength() {
            this.totalPages = Math.ceil(this.transferredForms.length / this.perPage);

            if (this.transferredForms.length / this.totalPages === this.perPage) {
                this.currentPage = 1;
            }

            return this.totalPages;
        },
        usersParams() {
            return {
                query: {}
            };
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.ps-customizer-area {
    max-height: 26rem;
}
</style>
